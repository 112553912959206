import React from "react";
import gil from "../asset/trustBy/gils.png"
import united from "../asset/trustBy/united2.png"
import rangechem from "../asset/trustBy/rangechems.png"
import hico from "../asset/trustBy/hico.png"
import taar from "../asset/trustBy/taar1.png"
import redflag from "../asset/trustBy/redflag1.png"
import logo from "../asset/trustBy/logo.png"


const TrustedBy = () => {
  const logos = [
    { imgSrc: gil, altText: "Logo 1" },
    { imgSrc: united, altText: "Logo 2" },
    { imgSrc: rangechem, altText: "Logo 3" },
    { imgSrc: hico, altText: "Logo 3" },
    { imgSrc: taar, altText: "Logo 3" },
    { imgSrc: redflag, altText: "Logo 3" },
    { imgSrc: logo, altText: "Logo 3" },

  ];

  return (
    <div className="bg-darkColor py-12 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        {/* Text Section */}
        <div className="mb-8 md:mb-12">
          <h2 className="mb-4 text-center text-2xl font-bold text-primary md:mb-6 lg:text-3xl">
            Trusted by the best
          </h2>
          <p className="mx-auto max-w-screen-md text-center text-white md:text-md">
            We are trusted by some of the top companies around the globe for our
            unmatched quality and commitment.
          </p>
        </div>

        {/* Logos Section */}
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:gap-2">
          {logos.map((logo, index) => (
            <div
              key={index}
              className="flex flex-col  items-center justify-center text-center rounded-3xl bg-darkColor hover:bg-darkColor border-darkshade border-[0] hover:border-0 p-4"
            >
              <img
                src={logo.imgSrc}
                alt={logo.altText}
            
                className=" h-16  max-w-96 text-red"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
