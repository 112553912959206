import React, { useState } from 'react'
import Button from './Button';
import { Link , useLocation , useNavigate} from "react-router-dom";
import logo from "../asset/images/logo_light.png"
import { fadeIn } from "../data/variants";
import { motion } from "framer-motion";

const Nav = () => {

  const location = useLocation();
  const navigate = useNavigate();

    let Links =[
      {name:"HOME",link:"/"},
      {name:"SERVICES",link:"/services"},
      {name:"ABOUT",link:"/about"},
      {name:"OUR WORK",link:"/ourwork"},
      {name:"CONTACT US",link:"/contactus"},
    ];
    let [open,setOpen]=useState(false);

  return (
    <div className='  w-full fixed z-30 top-0 left-0'>
      <div className='md:flex items-center  justify-between bg-darkColor md:bg-darkColor  md:bg-opacity-0 backdrop-blur-xl rounded drop-shadow-lg bg-cover py-1 md:px-10 px-7 '>
      {/* <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-white'>
        <span className='text-3xl text-indigo-600 mr-1 pt-2'>
        <ion-icon name="logo-ionic"></ion-icon>
        </span>
        Softkel
      </div> */}
      <img src={logo} className=' h-16 ' ></img>
      
      <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-8 top-6  cursor-pointer md:hidden'>
     <span className='text-white'>
     <ion-icon name={open ? 'close':'menu'}  ></ion-icon>
     </span>
      </div>

      <ul
  
      
      className={`md:flex md:items-center md:pb-0 pb-12 md:bg-transparent bg-darkColor absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? ' top-20  ':'top-[-490px]'}`}>
        {
          Links.map((link)=>(
            <li key={link.name} className='md:ml-8 text-md font-semibold md:my-0 my-7'>
              <Link to={link.link} className={` hover:text-gray-400  duration-500 hover:underline-offset-8 hover:decoration-4 hover:underline  ${link.link === location.pathname ? ' underline underline-offset-8 decoration-2':''} ${link.link === location.pathname ? ' text-blue-700 font-bold text-lg ':'text-white text-sm'}`}>{link.name}</Link>
            </li>
          ))
        }
        {/* <Button>
          Contact us
        </Button> */}
      </ul>
      </div>
    </div>
  )
}

export default Nav
