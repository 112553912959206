import { CgWebsite } from 'react-icons/cg';
import {TbDeviceMobileCode} from 'react-icons/tb';
import {IoServerOutline} from 'react-icons/io5'
import {SiSap} from 'react-icons/si'
import { FaPeopleGroup, FaPeopleRoof } from "react-icons/fa6";
import { MdOutlineIntegrationInstructions , MdOutlineAppShortcut   } from "react-icons/md";
import {BsWindowFullscreen} from 'react-icons/bs';
import { VscGraph } from "react-icons/vsc";
import afterSaleApp from "../asset/projectsImages/afterSaleApp1.png"
import dealerApp from "../asset/projectsImages/dealerApp2.png"
import saleApp from "../asset/projectsImages/saleApp1.png"
import webPr from "../asset/projectsImages/webPR1.png"
import warehouse from "../asset/projectsImages/warehouse1.png"
import { RiRobot2Line } from "react-icons/ri";
import { GrTechnology } from "react-icons/gr";

export const Servicess=[
    {
        'title' : "Mobile and Web App Development",
        'subtitle' : "Bespoke web and mobile app development to meet unique business needs.",
        'icon' : CgWebsite
    },
    {
        'title' : "Consulting",
        'subtitle' : "Expert guidance and tailored solutions for business growth and success.",
        'icon' : FaPeopleGroup
    },
    {
        'title' : "ERP Implementation and Customization",
        'subtitle' : "Tailored ERP implementation and customization to streamline business operations effectively.",
        'icon' : IoServerOutline
    },
    {
        'title' : "Sustainability and Autonomous Systems",
        'subtitle' : "Innovative solutions for sustainability and autonomous systems to drive efficiency.",
        'icon' : RiRobot2Line
    },
    {
        'title' : "Digital Transformation",
        'subtitle' : "Empowering businesses with cutting-edge digital transformation strategies and solutions.",
        'icon' : MdOutlineAppShortcut
    }
    ,
    {
        'title' : "Data Analytics",
        'subtitle' : "Actionable insights through advanced data analytics for smarter decision-making.",
        'icon' : VscGraph
    }
    ,
    {
        'title' : "Emerging Technologies",
        'subtitle' : "Leveraging emerging technologies to innovate and transform business processes.",
        'icon' : GrTechnology
    }
    ,
    // {
    //     'title' : "Third Party Integration",
    //     'subtitle' : "SAP Services: Elevating Business Efficiency and Innovation.",
    //     'icon' : MdOutlineIntegrationInstructions   
    // }
];


export const Projectss=[
    {
        'title' : "Web PR",
        'subtitle' : "Streamlined web app for managing, tracking, and approving PR requests with ease.",
        'image' : webPr
    },
    {
        'title' : "Dealer App",
        'subtitle' : "Dealer app for logging daily sales, placing orders, and managing complaints efficiently.",
        'image' : dealerApp
    },
    {
        'title' : "After Sale App",
        'subtitle' : "After Sale app for managing complaints with roles for sales coordinators, supervisors, and service vendors.",
        'image' : afterSaleApp
    },
    {
        'title' : "Sale App",
        'subtitle' : "Sales app for users to easily post, track, and manage their sales.",
        'image' : saleApp
    },
    {
        'title' : "Warehouse Management System",
        'subtitle' : "Efficient warehouse management app for inventory tracking, order processing, and real-time analytics.",
        'image' : warehouse
    },
    
    
];